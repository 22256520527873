* {
  box-sizing: border-box;
}

html,
body, button, input, textarea {
  font-family: "Manrope", sans-serif;
  font-size: 1.2rem;
  color: white;
  overflow: hidden;


  &::placeholder {
    color: rgb(255, 39, 169);
  }

  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }

}

#overlay {
  z-index: 1;
  position: fixed;
  top: 0;
  left:0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

#hero, #root {
  height: 100vh;
  // overflow: hidden;
  position: relative;
}

#down {
  position: absolute;
  top: 0px;
  width: 10vw;
  pointer-events: none;
}

#up {
  position: absolute;
  top: 0px;
  right: 0;
  width: 10vw;
  pointer-events: none;
}

.panel {
  height: 100vh;
  position: relative;
}

.drag-drop-zone {
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body {
  padding: 0;
  margin: 0;
  background-color: black;
}

.drag-drop-zone {
  width: 100%;
}

.fullscreen {
  position: absolute;
  top:0; left:0; right:0; bottom: 0;
}

.grid {
  display: grid;
  gap: 10vw;
}

.vertical-center {
  place-content: center;
  align-items: center;
}

.two-columns {
  grid-template-columns: 1fr 30vw;

  align-items: center;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }

}

.no-mouse {
  pointer-events: none;
}

.pad {
  padding: 5vw
}

.show {
  opacity: 1;
  transition: opacity ease-out 0.3s;
}

.hide {
  opacity: 0;
  transition: opacity ease-out 0.3s;
}

button {
  pointer-events: all;
}

form {
  width: 100%;
}

form button,
.save-button {
  font-size: 1em;
  background-color: #2bf6ec;
  color: #25282F;
  border-radius: 6px;
  padding: 8px 10px;
}

textarea, input {
  background-color: transparent;
  border-radius: 10px;
  border: none;
  outline: none;
  color: white;
  border: 2px solid rgb(255, 39, 169);
  font-size: 16px;
  font-weight: 100;
  padding: 10px;
  width: 100%;
}

input[type="checkbox"] {
  text-align: left;
  font-size: 2em;
  width: 18px;
  scale: 2;
  margin-right: 0.3em;
}

textarea {
  min-height: 10vw;
}

.page {
  width: 100vw;
  min-height: 100vh;
  padding: 8vw 8vw;
  background: linear-gradient(45deg, rgba(27, 1, 66, 0.736), rgba(39, 3, 96, 0.9));
  @media screen and (max-width: 1024px) {
    padding: 16vw 8vw;
    padding-bottom: 50vw;
  }

}

header {
  position: fixed;
  z-index: 2;
  background: linear-gradient( rgba(0,0,0,0.9), rgba(0,0,0,0.5));
  width: 100vw;
  padding: 1vw 8vw;
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: 14px;

  h1 {
    font-size: 14px;
  }

  button {
    text-transform: uppercase;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 20px;
    background: rgba(144, 25, 204, 0.5);
    border-radius: 100px;
  }

}

h1, h2, h3 {
  font-size: 1em;
}

a {
  color:rgb(255, 39, 169);
}